.main-content {
  padding: 20px 0 0 0 !important;
  overflow-y: scroll !important;
}

.page-content {
  padding: 56px 2px 2px 2px !important;
}

.page-section {
  margin-bottom: 83px;
}

.sync-modal {
  min-width: 350px;

  .modal-content {
    padding: 50px;
  }
}

.topline {
  display: flex;
  flex-direction: column;

  .topline-inner-container {
    min-height: 50vh !important;
  }
}

.actionBarTop {
  flex-shrink: 0;
}

.container-fluid {
  padding-left: 0.6rem !important;
  // padding-bottom: 50px;
  // height: calc(100vh - 160px);
  // height: 100%;
  flex: 1 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.warning-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e77e40;
}

.warning-feedback:before {
  content: '⚠️ ';
  padding-left: 6px;
  padding-right: 6px;
}

.invalid-feedback {
  display: block !important;
}
