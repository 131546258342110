.tasklist-filter-card-container {
  display: inline-block;
  box-shadow: var(--bs-box-shadow);
  border-width: 0;
  border-style: solid;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: var(--bs-primary);

  cursor: pointer;
  box-shadow: 5px 5px 10px #dbd7d7;
  color: var(--bs-card-color);
  width: 100%; /* Default to 100% width */

  .task-state {
    background-color: white;
    font-size: 1.2em;
    font-weight: 700;
    border-top-right-radius: 10px;
    padding: 10px;
    &.selected {
      background-color: var(--bs-primary-bg-subtle);
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .task-step {
    color: var(--dark-blue);
    min-height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .task-text,
    .task-count {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
