.building-search {
  width: 100%;
  .address-list .address-item .checkbox:checked {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;

    background-color: var(--bs-primary);
  }
  .address-item {
    height: unset !important;
    left: unset !important;
    position: unset !important;
    top: unset !important;
    width: unset !important;
  }
}
