.Modules {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.WorkflowStates {
  display: flex;
  gap: 10px;
}

.notepad {
  min-height: 400px;
  padding: 0 !important;
  @media (max-width: 991px) {
    margin-top: 40px;
  }

  .np-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 0;
  }
}

.singleSection {
  margin-bottom: 20px;
  height: 100%;
}

.modulesSection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .moduleWrapper {
    flex-grow: 0;
    min-width: 440px;
    width: 440px;

    .module-title {
      word-wrap: break-word;
      background-color: var(--bs-primary-bg-subtle);
      color: var(--bs-primary-text-emphasis);
      font-size: 1.1em;
      font-weight: 600;
      border-top-right-radius: 10px;
      padding: 10px 20px;
      height: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .moduleWrapper:has(> h5) {
    margin-top: 10px;
    margin-bottom: 6px;
    flex-basis: 100%;
    flex-direction: column;
  }
}

.Filter {
  padding: unset !important;
  border-radius: unset !important;
  background: unset !important;
  box-shadow: unset !important;
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1023px) and (max-width: 1449) {
    grid-template-columns: repeat(4, 1fr);
  }

  /* Tablets (3 columns) */
  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }

  /* Mobile (1 column) */
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.MainSection {
  display: flex;
  gap: 20px;
  width: 100%;
}

.MainSectionAreaLeft {
  flex-grow: 1;
  min-width: 300px;
  flex-direction: column;
  min-height: 400px;
}

.MainSectionAreaRight {
  flex-grow: 0;
  min-width: 300px;
  max-width: 300px;
}

@media (max-width: 1024px) {
  .MainSection {
    flex-wrap: wrap;
  }

  .MainSectionAreaRight {
    width: 100%;
  }
}
