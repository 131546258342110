.tasklist_filters {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 14px;
  margin-top: 14px;
  position: relative;
}

.tasklist_filter_button {
  // flex: 1 1 260px;
  // max-width: 400px;
  // min-width: 200px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 6px 16px rgba(58, 70, 84, 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  font-size: 12pt;

  &:hover {
    background-color: #f5f5f5;
    border-color: #b0b0b0;
    box-shadow: 0px 6px 16px rgba(58, 70, 84, 0.3);
  }

  .side_bar {
    background: #f79356;
    width: 6px;
    height: 100%;
    border-radius: 4px 0 0 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .side_bar.filter_active {
    background: #16cc59;
  }

  .content_badge {
    background: #f79356;
    border-radius: 10px 10px 10px 10px;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-weight: 600;
  }

  .content_none {
    font-weight: 400;
    color: #bbbbbb;
  }

  svg {
    margin-right: 8px;
  }
}

/* Medium screens (Tablets, Small Laptops) */
@media (max-width: 1024px) {
  .tasklist_filter_button {
    flex: 1;
    min-width: 45%;
  }
}

/* Small screens (Large Phones, Small Tablets) */
@media (max-width: 768px) {
  .tasklist_filter_button {
    flex: unset;
    width: 100%;
  }
}

.filter_active {
  .content_badge {
    background: #16cc59;
  }
  .content_none {
    color: black;
  }
}
