.widget-component {
  .slick-slider {
    svg {
      cursor: pointer;
    }

    .slick-list {
      width: 100%;
    }
  }
  .w-date-container {
    .weather-slider {
      padding: unset !important;
    }
  }
}
