.multi-select-container {
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  padding: 0.5rem 0.8rem;
  .label {
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    // color: var(--grey-800);
  }
  .form-check-input:checked {
    --bs-form-check-bg-image: none;
  }
}
