.taskHistoryInfo {
  width: 100%;

  td {
    font-size: 10pt;
    vertical-align: top;
  }

  td:nth-child(1) {
    width: 140px;
  }
}

.taskHistoryInfoSimple {
  width: 100%;

  td {
    font-size: 10pt;
    vertical-align: top;
  }

  td:nth-child(1) {
    width: 210px;
  }
}
