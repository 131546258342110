.title {
  margin-right: 12px;
}
.subtitle {
  margin-bottom: 8px;
}

.stdtext {
  margin-bottom: 10px;
}

.stdtext + .stdtext {
  margin-bottom: 4px;
}
