.buttonMenu
{

  .btn
  {
    width: 51%;
    min-width: 320px;
    max-width: 400px;
    margin-bottom: 6px;
    text-align: left;
    clear: both;
    // height: 60px;
  }

  // .btn:after
  // {
  //   content: ">";
  //   position: absolute;
  //   right: 20px;
  // }

  br
  {
    line-height: 10px;
    min-height: 10px;
    margin-bottom: 30px;;
    padding: 10px;
    clear: both;
    content: "&nbsp;"
  }

}
