.ag-header-align-left .ag-header-cell-label {
  justify-content: start;
}

.ag-header-align-center .ag-header-cell-label {
  justify-content: center;
}

.ag-header-align-right .ag-header-cell-label {
  justify-content: end;
}

.grid-link-btn {
  font-size: 13px;
  padding: 1px 12px;
}

.grid-table {
  height: calc(100vh - 220px);
}

// When the filter buttons are showing, this will fix the table height.  May need to be
// adjusted if the padding and margins on the Filter class change.
div:has(.Filter) > .col-12 + .col-12 .grid-table {
  height: calc(100vh - 320px);
}

.grid-table .with_filter {
  height: calc(100vh - 300px);
}

.page-grid-table {
  height: calc(100vh - 220px);
}

div:has(.Filter) > .col-12 + .col-12 .page-grid-table {
  height: calc(100vh - 320px);
}

:has(.tasklist_filters) .grid-table {
  height: calc(100vh - 220px - 70px);
}
