#disabled_textarea {
  width: 100%;
  height: 50px;
}

.toolbarClassName,
.editorClassName {
  border: 1px solid var(--light-grey);
  border-radius: 4px;
}

.disabled-input {
  opacity: 0.7;
}

.toolbarClassName {
  background: #f5f5f5;
  margin-bottom: 0;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  .rdw-option-wrapper {
    border-radius: 4px;
  }
}

.editorClassName {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-right: 15px !important;
  height: fit-content;
  max-height: 300px;
  word-break: break-word;
}
