:root {
  --io-yellow: #68000d;
  --io-primary: #54565b;
  --io-blue: #253746;
  --io-blue-900: #68000d;
  --io-blue-400: #f1f6f6;
  --io-primary-bg-subtle: #dfe5f2;
  --io-primary-text-emphasis: #54565b;
  --dark-blue: #253746;
  --light-grey: #ced4da;
  --grey-800: #7b92a5;
  --sbp-light-grey: #e6e6e699;
  --sbp-grey: #d3d3d3;
  --io-primary-button: #00a6b6;
  --io-primary-button-border: #00a6b6;

  --io-secondary-button-bg: #b5be00;
}
