.errorMessage {
  color: red;
  font-weight: bold;
  padding: 10px;
}

.switchUserWrapper {
  width: 100%;
  padding: 10px;
}

.switchUser {
  td {
    border: 1px solid #dddddd;
    background-color: #fff;
    padding: 10px;
  }

  td:nth-child(1) {
    width: 200px;
    background-color: #eeeeee;
  }
}

.currentUserTableWrapper {
  width: 100%;
  padding: 10px;
}

.currentUserTable {
  width: 100%;

  td {
    padding: 10px;
    border: 1px solid #dddddd;
    background-color: #fff;
  }

  td:nth-child(1) {
    width: 200px;
    background-color: #eeeeee;
  }
}

.roleSelect {
  cursor: pointer;
  text-decoration: underline;
  color: #000090;
}

td.roleSelect:hover {
  background-color: #feeeee;
}

.help {
  padding: 10px;
  font-size: 1em;
  // max-width: 800px;
  color: #2a155c;
}

.passwordWrapper {
  padding: 10px;
}

.debugDiv td {
  padding: 10px;
  vertical-align: top;
}

.testAccountLabel {
  width: 120px;
  text-align: left;
  vertical-align: middle;
  float: left;
  background-color: #c0c0c0;
  height: 44px;
  padding-left: 10px;
  padding-top: 10px;
  margin-right: 10px;
}

.testAccountRow {
  padding-top: 4px;
  width: 100%;

  .btn-group .btn-secondary {
    border-left: 1px solid #bbbbbb;
  }

  .btn-group .btn-secondary:nth-child(1) {
    border-left: 0px;
  }
}
