.modal-header {
  cursor: move;
  align-items: center;
  border-bottom: 1px solid #eff2f7;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem;
}
.modal-content {
  background-color: white;
  border: none;
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}
.react-draggable {
  z-index: 9999;
  position: absolute !important;
  border: 1px solid #303030;
  box-shadow: 5px 5px 10px #909090;
}

.json-view {
  span {
    font-weight: normal;
    font-size: 12px;
  }
}
