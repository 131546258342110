.configurations {
  .header {
    text-decoration: underline;
  }
  .form-switch input {
    height: 25px;
    width: 45px;
    border: none;
    &.form-check-input {
      background-color: #cccccc;
    }
    &.form-check-input:focus {
      box-shadow: none;
    }

    &.form-check-input:checked {
      background-color: #409ba6;
    }
  }
  input {
    height: 30px;
  }
  button {
    height: 40px;
    &.btn-primary {
      background-color: #409ba6;
    }
  }
}
