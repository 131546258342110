.task-title-container {
  width: 100%;
  align-items: center;
  .task-value {
    flex-basis: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .task-badge {
    flex-basis: '15%';
    flex-shrink: 0;
  }
}
