.textWithLabelRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;

  .labelPart {
    flex-grow: 0;
    min-width: 240px;
    width: 240px;
    font-weight: 550;
  }

  .dataPart1 {
    flex-grow: 1;
    min-width: 300px;
    width: 300px;
    word-wrap: break-word;
  }

  .dataPart1 ul,
  ol {
    padding-left: 20px;
  }
}

br + h5 {
  padding-top: 20px;
}

.textWithLabelRowDouble {
  background-color: blue;
  .labelPart {
  }

  .dataPart1 {
    flex-grow: 1;
    min-width: 220px;
    width: 220px;
  }

  .dataPart2 {
    flex-grow: 1;
    min-width: 220px;
    width: 220px;
  }

  .dataPart1 ul {
    padding-left: 20px;
  }
}

.textWithLabelRow + h5 {
  margin-top: 30px;
}

h5 + .textWithLabelRow {
  border-top: 1px solid #909090;
}

.textWithLabelRow + .textWithLabelRow {
  border-top: 1px solid #eeeeee;
}

.textWithLabelRow + button {
  margin-top: 20px;
}
