.workflow-container {
  display: inline-block;
  box-shadow: var(--bs-box-shadow);
  border-width: 0;
  border-style: solid;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: var(--bs-primary);

  cursor: pointer;
  box-shadow: 5px 5px 10px #dbd7d7;
  color: var(--bs-card-color);
  width: 170px;

  .workflow-state {
    background-color: white;
    font-size: 1.2em;
    font-weight: 700;
    border-top-right-radius: 10px;
    padding: 10px;
    &.selected {
      background-color: var(--bs-primary-bg-subtle);
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .workflow-state-step {
    color: var(--dark-blue);
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .workflow-text {
      font-size: 13px;
      margin-bottom: 0;
    }
    .workflow-spinner {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
.workflow-icon {
  color: var(--bs-primary);
}

.workflow-container-share {
  display: inline-block;
  box-shadow: var(--bs-box-shadow);
  border-width: 0;
  border-style: solid;
  border-left-width: 10px;
  border-radius: 5px;
  border-color: var(--bs-primary);

  cursor: pointer;
  box-shadow: 5px 5px 10px #dbd7d7;
  color: var(--bs-card-color);
  width: 110px;

  .workflow-state {
    background-color: white;
    font-size: 1.2em;
    font-weight: 700;
    border-top-right-radius: 10px;
    padding: 10px;
    &.selected {
      background-color: var(--bs-primary-bg-subtle);
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .workflow-state-step {
    color: var(--dark-blue);
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .workflow-text {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.workflow-container-share.generating {
  width: 260px;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dropdown-background {
  position: absolute;
  z-index: 9000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dropdown-showing {
  visibility: visible;
  display: block;
}

.dropdown-hidden {
  visibility: hidden;
  display: none;
}

.dropdown-menu {
  z-index: 10000;
  position: absolute;
}

.custom-modal {
  background-color: transparent !important;
}

.custom-modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-modal-backdrop {
  opacity: 0.5 !important;
}
