.tabList {
  // border-bottom: 2px solid white;

  .tabContainer {
    flex-grow: unset;
    flex-basis: unset;
    .tab {
      font-size: 15px;
      font-style: normal;
      color: rgba(37, 55, 70, 0.5);
      &::after {
        height: 5px !important;
        border-radius: 4px !important;
      }
    }
    .active {
      font-weight: 600 !important;
      background-color: transparent;
    }
  }
}

.tabBody {
  // margin-top: 0.3rem;
  p {
    font-size: 14px;
    color: #54565b;
  }
  h5,
  h4 {
    color: #253746;
    font-size: 15px;
    font-weight: 600;
  }
}

.bpadleft {
  padding: 5px;
}

.tooltip:before {
  content: attr(data-text-tip); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}

.tabWarning {
  color: #68000d !important;
  vertical-align: middle;
  padding-top: 10px;
  font-size: 15px;
  padding-bottom: 10px;
  padding-left: 40px;
}
