.breadcrumblink {
  color: var(--io-primary-text-emphasis);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  &.active {
    color: var(--bs-primary);
    font-weight: 600;
  }
}
