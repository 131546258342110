.form-image
{
  padding-top: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  align-items: center;
}
.form-image:nth-child(1)
{
  margin-left: 0px;
  padding-left: 0px;
}
.form-image img
{
  margin-bottom: 10px;
  cursor:pointer;
}
.form-image label
{
  padding-left: 8px;
}
