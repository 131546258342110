.sort-icon {
  margin: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s;
  transform: rotate(0deg);
  color: #000;
  opacity: 0.5;
}

.fast-data-table {
  width: 100;
  background-color: white;
  font-size: 0.9em;

  .Number {
    text-align: right;
  }

  .Currency {
    text-align: right;
  }
}

.row-ellipse {
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@mixin tablet-and-up {
  @media screen and (min-width: 769px) {
    @content;
  }
}
@mixin mobile-and-up {
  @media screen and (min-width: 601px) {
    @content;
  }
}
@mixin tablet-and-down {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin mobile-only {
  @media screen and (max-width: 600px) {
    @content;
  }
}

.table-timeline {
  ul,
  li {
    list-style: none;
    padding: 0;
  }

  li {
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    font-size: 0.85em;
    &:last-child {
      border: 0px;
      padding-bottom: 0;
    }
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: white;
      border: 1px solid #4e5ed3;
      // box-shadow: 3px 3px 0px #bab5f8;
      // box-shadow: 3px 3px 0px #bab5f8;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      top: 0px;
    }
  }

  .time {
    color: #2a2839;
    font-family: 'Poppins', sans-serif;
    font-size: 0.85em;
    font-weight: 500;
    @include mobile-and-up {
      font-size: 0.9rem;
    }
    @include mobile-only {
      margin-bottom: 0.3rem;
      font-size: 0.85rem;
    }
  }

  .changedto {
    color: #401066;
  }
}
