.theme-io .vertical-menu .navbar-brand-box,
.theme-io #sidebar-menu ul li ul.sub-menu li {
  border-bottom: 0px;
}

.theme-io {
  .vertical-menu {
    .navbar-brand-box {
      padding-left: 0;

      .logo-lg {
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .card-body
  {
    color: #54565b !important;
  }

  .text-primary
  {
    color: #253745 !important;
  }

  .btn-edit
  {
    background-color: var(--io-primary-button);
  }

  .border-primary
  {
    border-color: #68000d !important;
  }
  // .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  .btn-primary{
    background-color: var(--io-primary-button);
    border-color: var(--io-primary-button-border);
  }

  .btn-success 
  {
    background-color: #54565b;
    border-color: #54565b;
  }

  .btn-secondary{
    background-color: #253746;
  }

  

  .theme-io #sidebar-menu > ul > li > a.active,
  .theme-io #sidebar-menu > ul > li > a.mm-active {
    background-color: #00a6b6 !important;
  }

  .container-fluid {
    padding-left: 1.5rem;
  }
  .tabContainer {
    .tab {
      &::after {
        background-color: var(--io-yellow);
      }
    }
    .active {
      color: #253746 !important;
    }
  }
  .notepad {
    .np-header {
      background-color: var(--io-yellow);
    }
  }
  .module-button {
    background-color: white;
    &.Primary {
      border-color: var(--io-primary);

      .module-title {
        background-color: var(--io-primary-bg-subtle);
      }

      .module-description {
        color: var(--io-primary-text-emphasis);
      }
    }
  }
  .workflow-container {
    border-color: var(--io-blue);

    .workflow-state {
      &.selected {
        background-color: var(--io-blue-400);
      }
    }
  }
  .workflow-icon {
    color: var(--io-blue-900);
  }
  .breadcrumblink {
    &.active {
      color: var(--io-primary);
    }
  }
  .multi-select-container {
    .form-check-input:checked {
      background-color: var(--io-primary);
      border: none;
    }
  }
  .form-check {
    .radio-input {
      &:checked::after {
        background-color: var(--io-primary);
      }
    }
  }
  .building-search .address-list .address-item .checkbox:checked {
    background-color: var(--io-primary);
  }
}
