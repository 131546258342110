h4 {
  margin-bottom: 20px;
}

.menu-module-button {
  display: inline-block;
  background-color: var(--bs-body-bg);
  box-shadow: var(--bs-box-shadow);
  border-width: 0;
  border-style: solid;
  border-left-width: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: 5px 5px 10px #dbd7d7;
  color: var(--bs-card-color);
  // padding: 0;

  .module-title {
    word-wrap: break-word;
    background-color: var(--bs-primary-bg-subtle);
    color: var(--bs-primary-text-emphasis);
    font-size: 1.1vmax;
    font-weight: 600;
    border-top-right-radius: 10px;
    padding: 10px 20px;
    height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .module-description {
    color: var(--bs-primary);
    padding: 10px 20px;
    min-height: 60px;
    font-size: 13px;
  }

  &:hover {
    opacity: 0.8;
    box-shadow: 5px 5px 10px #000000;
  }

  &:active {
    position: relative;
    z-index: 1000;
    top: 4px;
    left: 4px;
  }

  &.Primary {
    border-color: var(--bs-primary);

    .module-title {
      border-color: var(--bs-primary);
      background-color: var(--bs-primary-bg-subtle);
      color: var(--bs-primary-text-emphasis);
    }

    .module-description {
      color: var(--bs-primary);
    }
  }

  &.Danger {
    border-color: #68000d;

    .module-title {
      border-color: var(--bs-primary);
      background-color: #e6c6ca;
      color: #68000d;
      font-weight: 600;
    }

    .module-description {
      color: #68000d;
    }
  }

  &.Secondary {
    border-color: var(--bs-secondary);

    .module-title {
      border-color: var(--bs-secondary);
      background-color: var(--bs-secondary-bg-subtle);
      color: var(--bs-secondary-text-emphasis);
    }

    .module-description {
      color: var(--bs-secondary);
    }
  }

  &.Success {
    border-color: var(--bs-success);

    .module-title {
      border-color: var(--bs-success);
      background-color: var(--bs-success-bg-subtle);
      color: var(--bs-success-text-emphasis);
    }

    .module-description {
      color: var(--bs-success);
    }
  }

  &.Info {
    border-color: var(--bs-info);

    .module-title {
      border-color: var(--bs-info);
      background-color: var(--bs-info-bg-subtle);
      color: var(--bs-info-text-emphasis);
    }

    .module-description {
      color: var(--bs-info);
    }
  }

  &.Warning {
    border-color: var(--bs-warning);

    .module-title {
      border-color: var(--bs-warning);
      background-color: var(--bs-warning-bg-subtle);
      color: var(--bs-warning-text-emphasis);
    }

    .module-description {
      color: var(--bs-warning);
    }
  }
}
