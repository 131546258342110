.actionBarTop {
  // padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-top: 0.5rem;
  button {
    margin-right: 6px;
  }

  button:active {
    position: relative;
    z-index: 1000;
    top: 2px;
    left: 2px;
  }

  .actionMessage {
    font-size: 1.2em;
    padding-bottom: 12px;
  }
}

.messageWrapper {
  position: absolute;
  bottom: 10px;
  left: 300px;
  padding: 6px;
  font-family: monospace;
  font-size: 11px;
  color: #606060;
}
